.work-display-main {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 0rem;
  margin-left: 16rem;
  padding-top: 4rem;
}
.work-display-divs {
  margin: 2rem 0;
}
.work-display-textarea-up {
  width: 54rem;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}
.work-display-title {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 5.6rem;
  letter-spacing: -0.827586px;
  color: var(--secondary-color);
}
.work-display-desc {
  font-family: "CircularStd-Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: #7c8087;
  margin-top: 2.2rem;
}
.work-display-textarea-down {
  width: 56.2rem;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  justify-content: center;
  align-items: flex-start;
}
.textarea-icon-text {
  font-family: "CircularStd-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: var(--secondary-color);
}
.icon {
  width: 6.56rem;
  height: 6.56rem;
  /* margin-bottom: 3rem; */
}
.work-icon-and-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.work-dsiplay-image {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .work-display-main {
    grid-template-columns: 50% 50%;
  }
}
@media (max-width: 1000px) {
  .work-display-main {
    grid-template-columns: 45% 55%;
    margin-left: 8rem;
  }
  .work-display-textarea-up {
    align-items: flex-start;
  }
  .work-display-desc {
    width: 90%;
  }
}

@media (max-width: 870px) {
  .work-display-main {
    margin-left: 0;
    grid-template-columns: 1fr;
    place-items: center;
  }
  .work-dsiplay-image {
    grid-row-start: 2;
    grid-column-start: 1;
    margin-left: 9vw;
    width: 80%;
  }
}

@media (max-width: 700px) {
  .work-display-main {
    margin-left: 0;
    grid-template-columns: 1fr;
    place-items: center;
  }
  .work-dsiplay-image {
    grid-row-start: 2;
    grid-column-start: 1;
    margin-left: 9vw;
  }
}

@media (max-width: 500px) {
  .work-display-main {
    place-items: start;
    padding-left: 6.3rem;
  }
  .work-dsiplay-image {
    margin: 0;
    object-fit: contain;
    width: 110%;
    margin-left: -6rem;
  }

  .work-display-title {
    font-size: 4.8rem;
    line-height: 6.8rem;
    margin-bottom: 3rem;
  }
  .work-display-desc {
    font-size: 2.8rem;
    line-height: 4.2rem;
  }
  .work-display-textarea-down {
    margin: 0;
    margin-bottom: 4rem;
  }
  .textarea-icon-text {
    font-size: 3rem;
    line-height: 5.4rem;
  }
}
