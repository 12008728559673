.secondary-color {
  color: var(--secondary-color);
}

.blogs-container {
  width: 85%;
  height: fit-content;
  padding-top: 7.37rem;
  padding-bottom: 11rem;
  position: relative;
  margin: 0 auto;
}

.blogs-container::after {
  content: "";
  position: absolute;
  width: 8%;
  height: 8%;
  background: url("../../../../assets//images/homePage/dottedNetMask.png");
  background-size: contain;
  background-repeat: no-repeat;
  left: -1.5%;
  top: 45%;
}

button {
  outline: none;
  border: none;
}

.blogs-showcase-div {
  padding-top: 6.9rem;
  width: 85%;
  display: flex;
  gap: 12rem;
  margin: 0 0 0 auto;
}

.main-blog-post-div {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  box-shadow: 0px 10px 20px 0px #5f5f5f26;
  background-color: #fcffff;
}

.main-post-image {
  width: 100%;
}
.main-post-image > img {
  width: 100%;
}
.blog-readmore-btn-style {
  width: fit-content;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.other-blog-posts-div {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
  width: 55%;
}

.blogs-container .other-singlepost-image > img {
  width: 242px;
  height: 160px;
  object-fit: cover;
  border-radius: 8px;
}

.other-single-blogpost {
  display: flex;
  gap: 4rem;
  height: max-content;
}

.blog-data-and-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.blog-data-and-buttons .blog-readmore-btn-style {
  /* position: absolute; */
  bottom: 0;
  left: 0;
}

.main-blog-content {
  padding: 20px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-evenly;
}

.main-blog-post-div > .main-blog-content > p:nth-of-type(1),
.blog-data-and-buttons > p:nth-of-type(1) {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: var(--secondary-color);
}

.main-blog-post-div > .main-blog-content > p:nth-of-type(2),
.blog-data-and-buttons > p:nth-of-type(2) {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.6rem;
  color: #838383;
}
.blog-data-and-buttons > p:nth-of-type(1),
.blog-data-and-buttons > p:nth-of-type(2) {
  width: 100%;
}

.blog-readmore-btn-style > p {
  margin-right: 1.9rem;

  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--primary-color);
}
.readmore-btn-dash {
  width: 5rem;
  height: 0.2rem;
  background: var(--primary-color);
  transform: translateX(1.2rem);
}

.view-all {
  color: #45b2f6;
  padding-left: 4px;
}

/********************************************************/

@media (max-width: 1650px) {
  .blogs-showcase-div {
    gap: 10rem;
  }
}
@media (max-width: 1550px) {
  .blogs-container .other-singlepost-image > img {
    width: 180px;
    height: 140px;
  }
}
@media (max-width: 1350px) {
  .blogs-showcase-div {
    width: 90%;
    gap: 8rem;
  }
  .blogs-container .other-singlepost-image > img {
    width: 145px;
    height: 140px;
  }
}
@media (max-width: 1280px) {
  .blogs-showcase-div {
    width: 93%;
    gap: 6rem;
  }
}

@media (max-width: 1080px) {
  .blogs-container .main-blog-post-div,
  .blogs-container .other-blog-posts-div {
    width: 100%;
  }
  .blogs-showcase-div {
    width: 80%;
    flex-direction: column;
    margin: 0 auto;
  }
  .blogs-container::after {
    display: none;
  }
  .blogs-container .other-singlepost-image > img {
    width: 260px;
    height: 150px;
  }
}

@media (max-width: 991px) {
  .blogs-showcase-div {
    width: 100%;
  }
}
/* @media (max-width: 1230px) {
  .blog-data-and-buttons > p:nth-of-type(1) {
    width: 42rem;
  }
  .blog-data-and-buttons > p:nth-of-type(2) {
    width: 44rem;
  }
  .blogs-container {
    padding-top: 11.37rem;
  }
} */
@media (max-width: 1150px) {
  .blogs-showcase-div {
    gap: 5rem;
  }
  .main-post-image {
    width: 100%;
  }
}

/* @media (max-width: 905px) {
  .main-post-image > img {
    width: 90%;
  }
} */

@media (max-width: 575px) {
  .other-single-blogpost {
    flex-direction: column;
  }
  .blogs-container .other-singlepost-image > img {
    width: 100%;
    height: 100%;
  }
}

/* @media (max-width: 525px) {
  .blogs-container {
    padding-top: 24.37rem;
  }
} */

@media (max-width: 765px) {
  .blogs-showcase-div {
    gap: 5rem;
  }

  .main-blog-post-div > .main-blog-content > p:nth-of-type(1) {
    font-size: 4rem;
    line-height: 6.8rem;
  }
  .main-blog-post-div > .main-blog-content > p:nth-of-type(2) {
    font-size: 2.8rem;
    line-height: 5.2rem;
  }

  .blog-data-and-buttons > p:nth-of-type(1) {
    font-size: 3.2rem;
    line-height: 4.2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  .blog-data-and-buttons > p:nth-of-type(2) {
    font-size: 2.8rem;
    line-height: 3.8rem;
    width: 100%;
  }

  .blog-readmore-btn-style {
    margin: 10px 0 4px;
  }

  .blog-readmore-btn-style > p {
    font-size: 2.2rem;
    line-height: 4.2rem;
  }
}

@media (max-width: 575px) {
  .blogs-container {
    padding-top: 0;
  }
}

/* @media (max-width: 447px) {
  .blogs-container {
    padding-top: 16rem;
  }
}

@media (max-width: 373px) {
  .blogs-container {
    padding-top: 15rem;
  }
} */

/* @media (max-width: 329px) {
  .blogs-container {
    padding-top: 23rem;
  }
}

@media (max-width: 315px) {
  .blogs-container {
    padding-top: 31rem;
  }
}

@media (max-width: 311px) {
  .blogs-container {
    padding-top: 42rem;
  }
}

@media (max-width: 300px) {
  .blogs-container {
    padding-top: 4rem;
  }
} */
