body.no-scroll {
  overflow: hidden;
}


.modalOverlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
}
.modalContent {
  background-color: white;
  box-shadow: #000 0px 10px 38px -10px, #000 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 551px;
  max-height: 421vh;
  padding: 32px;
  border-radius: 16px;
  z-index: 1;
}
.error {
  display: block;
  color: red;
  font-size: 16px;
  margin-bottom: 10px;
}
.successMessage {
  color: green;
  padding-top: 10px;
}
.IconButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.IconButton svg {
  background: #fff;
  border: 0;
}
.modalTitle,
.modalSubtitle {
  text-align: center;
}

.modalTitle {
  font-size: 32px;
  font-weight: 500;
  padding: 20px 0 12px;
}
.modalSubtitle {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 0 0 32px;
}
.formSubmitBtn {
  background: #3fa0ef;
  padding: 12px 0;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
}
.form-item label {
  font-size: 14px;
  color: #757779;
}
.form-control::placeholder {
  color: #757779;
  opacity: 0.8;
}

.form-control:focus {
  box-shadow: none;
}
.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px 26px;
  border: 1px solid #e5ece9;
}

.form-item input {
  font-size: 16px;
  padding-top: 4px;
}

.form-item input::placeholder {
  color: #757779;
  opacity: 0.8;
}

.outter-container {
  padding-top: 124px;
  padding-bottom: 124px;
  gap: 100px;
  display: flex;
  flex-direction: column;
}
.content-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.company-brouchers {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.company-brouchers .section_head {
  font-size: 36px;
  font-weight: 500;
  line-height: 43.21px;
  color: #1c2841;
}
.company-brouchers .section_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}
.company-brouchers .section_grid .broucher {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.section_grid .broucher .imageContainer {
  position: relative;
  overflow: hidden;
  display: flex;
}
.section_grid .broucher .imageContainer img {
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 24px;
}
.company-brouchers .section_grid .broucher .imageContainer:hover img {
  opacity: 0.3;
}
.section_grid .broucher .imageContainer .overlay {
  border-radius: 24px;
  position: absolute;
  display: flex;
  flex: 1;
  padding: 20px;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.6s ease;
}
.section_grid .broucher .imageContainer .overlay .playIconWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}
.section_grid .broucher .imageContainer .overlay .playIconWrapper img {
  width: 79px;
}
.section_grid .broucher .imageContainer .overlay .hoverText {
  font-size: 20px;
  border-left: 6px solid #ffd300;
  padding-left: 4px;
  cursor: pointer;
}
.section_grid .broucher .imageContainer .overlay .hoverText h4 {
  font-family: Montserrat, sans-serif;
  font-size: 38.5px;
  font-weight: 600;
  line-height: 46.93px;
  color: #fff;
}
.section_grid .broucher .imageContainer .overlay .hoverText p {
  font-family: Montserrat, sans-serif;
  font-size: 22.5px;
  font-weight: 500;
  line-height: 27.43px;
  color: #fff;
}
.section_grid .broucher .imageContainer:hover .overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  cursor: pointer;
}
.section_grid .broucher .section_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section_grid .broucher .section_body .btn-group {
  gap: 16px;
}
.section_grid .broucher .section_body .btn-group button {
  box-shadow: 0px 2px 5px 0px #000 29;
  background: transparent;
  border-radius: 9px;
}
.section_grid .broucher .section_body .btn-group button img {
  object-fit: cover;
}
.section_grid .broucher .broucher_type {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  color: #1c2841;
}
.section_grid .broucher button {
  display: flex;
  font-size: 26px;
  gap: 10px;
  color: #1c75ba;
  background: transparent;
}
@media (min-width: 0) and (max-width: 479px) {
  .outter-container {
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 30px;
    display: flex;
    flex-direction: column;
  }
  .content-container .company-brouchers {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .company-brouchers .section_head {
    font-size: 28px;
    font-weight: 500;
    line-height: 43.21px;
    text-align: left;
    color: #1c2841;
  }
  .company-brouchers .section_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .section_grid .broucher {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .section_grid .broucher .imageContainer .overlay img {
    width: 59px;
  }
  .section_grid .broucher .imageContainer .overlay .hoverText {
    font-size: 20px;
    border-left: 4px solid #ffd300;
  }
  .broucher .imageContainer .overlay .hoverText h4 {
    font-size: 25.5px;
    line-height: 25.93px;
  }
  .broucher .imageContainer .overlay .hoverText p {
    font-size: 16.5px;
    line-height: 24.43px;
  }
  .section_grid .broucher .broucher_type {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #1c2841;
  }
  .section_grid .broucher button {
    display: flex;
    font-size: 22px;
    gap: 10px;
    color: #1c75ba;
    background: transparent;
  }
  .section_grid .broucher button img {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 480px) and (max-width: 1023px) {
  .outter-container {
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 30px;
    display: flex;
    flex-direction: column;
  }
  .company-brouchers {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .company-brouchers .section_head {
    font-size: 28px;
    font-weight: 500;
    line-height: 43.21px;
    text-align: center;
    color: #1c2841;
  }
  .company-brouchers .section_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .section_grid .broucher {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .section_grid .broucher .imageContainer .overlay img {
    width: 59px;
  }
  .section_grid .broucher .imageContainer .overlay .hoverText {
    font-size: 20px;
    border-left: 4px solid #ffd300;
  }
  .broucher .imageContainer .overlay .hoverText h4 {
    font-size: 25.5px;
    line-height: 25.93px;
  }
  .section_grid .broucher .imageContainer .overlay .hoverText p {
    font-size: 16.5px;
    line-height: 24.43px;
  }
  .section_grid .broucher .broucher_type {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #1c2841;
  }
  .section_grid .broucher button {
    display: flex;
    font-size: 22px;
    gap: 10px;
    color: #1c75ba;
    background: transparent;
  }
  .section_grid .broucher button img {
    width: 30px;
    height: 30px;
  }
}
