.service-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* outline: 1px solid blue;
     */
  margin-top: 8rem;
  height: 150rem;
  scroll-margin: 110px;
}

.service-heading {
  display: flex;
  flex-direction: column;
  margin-left: 16rem;
  margin-right: 22.5rem;
  /* outline: 1px solid red; */
  gap: 2.7rem;
}

.service-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.service-title-dash {
  width: 4.28rem;
  height: 0.142rem;
  mix-blend-mode: normal;
  border: 0.1rem solid #979797;
}

.service-title-text {
  display: flex;
  font-family: "Cabin-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #838383;
}

.service-heading-and-desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20rem;
}

.service-heading-text {
  width: 46.857rem;
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5.2rem;
  letter-spacing: 0.018rem;
  color: var(--secondary-color);
  padding-left: 5.15rem;
}

.service-heading-desc {
  width: fit-content;
  font-family: "Cabin-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #838383;
}

/***************** CARDS *****************/
.card-and-background-wrapper {
  position: relative;
  /* outline: 1px solid blue; */
  width: 100%;
  margin: 0 auto;
}
.service-cards {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  position: absolute;
  margin: 0px auto;
  top: 4.2%;
  margin-left: 16rem;
  margin-right: 16rem;

  min-height: fit-content;
  max-height: 100%;
}

.individual-card {
  width: 41rem;
  height: 38rem;
  padding-top: 5rem;
  padding-left: 3.5rem;
  background: #fcffff;
  box-shadow: 0px 5px 20px rgba(95, 95, 95, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.8rem;
}

.card-title {
  /* width: 21.2rem; */
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: var(--secondary-color);
}

.card-desc {
  width: 36.5rem;
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.7rem;
  color: #838383;
  padding-right: 2.7rem;
  margin-top: 2.5rem;
  height: 63.7%;
  overflow: hidden;
}
/* 
.learnMore-and-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 3rem;
}

.card-learn-more {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #fe743b;
  cursor: pointer;
}

.card-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8rem;
  gap: 0;
  cursor: pointer;
}

.card-arrow-head {
  color: #fe743b;
  font-size: 2.2rem;
}

.card-arrow-tail {
  width: 2.5rem;
  height: 0.2rem;
  background-color: #fe743b;
  transform: translateX(1.3rem);
  transition: width 0.3s linear;
} */

.individual-card::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, #5cbafa 0%, #2084c6 100%);
  transition: opacity 0.8s linear;
  opacity: 0;
}

.individual-card:hover::before {
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 5px 20px rgba(95, 95, 95, 0.1);
}

.individual-card:hover .hoverText {
  color: #ffffff;
  z-index: 1;
}
.individual-card:hover .card-arrow-tail {
  width: 4rem;
  background: #ffffff;
  transition: width 0.3s linear;
}

/***************** BackGround Div *****************/

.service-background-div {
  width: 100%;
  height: 117rem;
  background: #f2fbff;
  margin-top: 20rem;
}

@media (max-width: 1280px) {
  .service-cards {
    margin-left: 7rem;
  }
}

@media (max-width: 1230px) {

  .service-main {
    height: 218rem;
  }
  .service-background-div {
    height: 199rem;
  }

  .service-heading-and-desc {
    display: flex;
    flex-direction: column;
  }
  .service-heading-text {
    padding-left: 0;
  }
  .service-heading-desc {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .individual-card {
    /* height: auto; */
    padding-top: 0.6rem;
    padding-left: 0.7rem;
  }
  .service-cards {
    margin-top: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-left: 14rem;
  }
  .individual-card {
    padding-top: 7.7rem;
    padding-left: 2rem;
  }
  .learnMore-and-arrow {
    margin-top: 0;
  }
  .service-background-div {
    margin-top: 2rem;
  }
}

@media (max-width: 1208px) {
  .service-background-div {
    height: 198rem;
  }
}

@media (max-width: 800px) {
  .service-title-text {
    width: 90.857rem !important;
    height: fit-content;
  }
  .service-cards {
    margin-top: 0;
    top: 0;
  }
  .individual-card {
    padding-top: 7.7rem;
    padding-left: 2rem;
  }
  .learnMore-and-arrow {
    margin-top: 0;
  }
  .service-background-div {
    /* background-color: #ffffff; */
  }
  .service-cards {
    margin-left: 0rem;
    top: 0;
    width: 100%;

    background-color: #f2fbff;
  }
}

@media (max-width: 500px) {
  .service-heading {
    margin-bottom: 6rem;
  }
}

@media (max-width: 600px) {
  .service-heading {
    margin-left: 4.3rem;
    margin-bottom: 2rem;
  }
  .service-main {
    margin: 0 1rem;
    height: 211rem;
  }

  .individual-card {
    padding-top: 2rem;
    padding-left: 2rem;
  }
  .card-and-background-wrapper {
    height: fit-content;
  }
  .service-cards {
    padding: 1rem 0;
  }
  .service-background-div {
    height: 200rem;
  }
}

@media (max-width: 575px) {
  .service-main {
    margin: 5rem 0 0;
    height: 680rem;
  }
  .service-heading {
    margin-left: 6.5rem;
  }
  .service-heading-desc {
    width: 83vw;
  }
  .service-title-dash {
    width: 9.2rem;
  }
  .service-title-text {
    font-size: 3.6rem;
    line-height: 5.2rem;
  }
  .service-heading-text {
    width: 120%;
    font-size: 7.2rem;
    line-height: 10.4rem;
  }
  .service-heading-desc {
    font-size: 2.8rem;
    line-height: 5.2rem;
    margin: 4rem 0;
  }

  .service-background-div {
    height: 622rem;
  }

  .service-cards {
    padding: 8rem 0;
  }

  .individual-card {
    width: 81vw;
    height: 65rem;
    padding-top: 3rem;
    padding-left: 3rem;
  }
  .card-title {
    width: 100%;
    font-size: 4.8rem;
    line-height: 6.8rem;
  }
  .card-desc {
    width: 100%;
    font-size: 2.8rem;
    line-height: 5.2rem;
    height: 60%;
    overflow: hidden;
  }

  .card-learn-more {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }

  .card-arrow {
    margin-right: 20rem;
  }
  .card-arrow-head {
    font-size: 5.2rem;
  }
  
  .card-arrow-tail {
    width: 8.5rem;
    height: 0.4rem;
    transform: translateX(2.8rem);
  }

  .individual-card:hover .card-arrow-tail {
    width: 8.5rem;
  }
  
}
