.service-carousel-main {
  background: rgba(34, 176, 252, 0.06);
  padding-top: 10rem;
}
.service-carousel-heading {
  display: flex;
  margin-left: 16rem;
  margin-right: 22.5rem;
  align-items: center;
  justify-content: space-between;
}
.service-carousel-title {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 6.1rem;
  line-height: 7.2rem;
  letter-spacing: -0.1rem;
  color: var(--secondary-color);
  width: 545px;
}
.service-subtitle {
  display: block;
  margin-top: 1rem;
}

.service-carousel-actions {
  display: flex;
  gap: 2rem;
}
.left-btn,
.right-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.3rem;
  height: 6.3rem;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  transition: background-color 200ms ease-out;
}
.left-btn:hover,
.right-btn:hover {
  background-color: var(--primary-color);
  border: 1px solid white;
  color: white;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.service-carousel-container {
  padding-top: 10rem;
  padding-bottom: 11.5rem;
}
.individual-item {
  padding: 2rem 0 0 3rem;
  max-width: 30.6rem;
  height: 30.4rem;
  background: #ffffff;
}
.service-carousel-icon {
  margin-top: 8rem;
  margin-left: 1rem;
}
.service-carousel-category {
  font-family: "CircularStd-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 2.7rem;
  color: #1d293f;
  width: 60%;
  margin-top: 3rem;
}

.service-carousel-slider {
  max-width: 100%;
}

.service-slider-progressbar {
  margin-top: 6rem;
  display: none;
}

/*******************************************/
@media (max-width: 1750px) {
  .service-carousel-slider {
    margin-right: -500px;
  }
}
@media (max-width: 1550px) {
  .service-carousel-slider {
    margin-right: -200px;
  }
}
@media (max-width: 1100px) {
  .service-carousel-slider {
    margin-right: -100px;
  }
}
@media (max-width: 850px) {
  .service-carousel-slider {
    margin-right: -150px;
  }
}

@media (max-width: 650px) {
  .service-carousel-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 6.3rem;
  }
  .service-carousel-actions {
    margin-top: 4rem;
  }
}

@media (max-width: 500px) {
  .service-carousel-container {
    padding-left: 6.3rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .individual-item {
    padding: 0 0 0 3rem;
    max-width: 30rem;
    height: 39rem;
  }
  .service-carousel-icon {
    margin-top: 5rem;
  }
  .service-carousel-category {
    font-size: 3.2rem;
    line-height: 4rem;
  }
  .service-carousel-slider {
    margin-right: -10px;
  }

  .service-carousel-actions {
    display: none;
  }

  .service-slider-progressbar {
    display: block;
    width: 80%;
    height: 1.1rem;
    border-radius: 1rem;
    background-color: rgba(34, 176, 252, 0.2);
    position: relative;
  }

  .service-slider-progressDot {
    position: absolute;
    left: 0;
    width: 12%;
    height: 100%;
    background-color: #1d293f;
    border-radius: 1rem;
    transition: left 900ms ease-in-out;
  }
}
