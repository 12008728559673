.wwh-cards-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
}

.wwh-single-card {
  width: calc(80% / 3);
  min-width: 52rem;
  height: 34rem;
  background: #ffffff;
  border: 1px solid rgba(238, 238, 238, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.924rem;
  padding-inline: 3rem;
}

.WhatWeHave-subtitle {
  display: block;
  margin-top: 0.4rem;
}

.wwh-number-and-title {
  display: grid;
  grid-template-columns: 18% 72%;
  place-content: center;
}

.wwh-number {
  font-family: "Mazzard-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.2rem;
  letter-spacing: -0.1rem;
  color: #2084c6;
  opacity: 0.3;
}

.wwh-title {
  font-family: "Mazzard-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 4rem;
  letter-spacing: -0.05rem;
  color: var(--secondary-color);
  width: 40rem;
  margin-bottom: 2rem;
  min-height: 9rem;
}

.wwh-desc {
  font-family: "Mazzard-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 3rem;
  color: #1d263a;
  opacity: 0.8;
  height: 17rem;
  grid-column: 2 / 3;
  width: 28rem;
}

/*************************************************/
@media (max-width: 500px) {
  .wwh-single-card {
    width: 100%;
    padding-left: 10rem;
    height: 68rem;
    justify-content: flex-start;
    padding-top: 10rem;
  }

  .wwh-number {
    font-size: 6.4rem;
  }
  .wwh-title {
    width: 100%;
    font-size: 4.4rem;
  }
  .wwh-desc {
    font-size: 2.8rem;
    line-height: 4.2rem;
    width: 80%;
  }
}

@media (max-width: 323px) {
  .wwh-single-card {
    padding-top: 6rem;
  }
  .wwh-desc {
    font-size: 2.6rem;
  }
}
