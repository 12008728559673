.hero-section-container {
  width: 100%;
  height: 100rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.555rem;
  background-image: url(../../../../assets/images/homePage/heroSectionMask.png);
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;
}

.hero-heading {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 12.4rem;
  line-height: 13.4rem;
  letter-spacing: -0.1rem;
  color: #213053;
  text-align: center;
}

.hero-heading-title {
  color: var(--secondary-color);
}

.primary-color-text {
  color: var(--primary-color);
}
.ai{
  color: var(--primary-color);
  font-weight: 900;
}
.hero-description {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 3rem;
  text-align: center;
  color: #213053;
  opacity: 0.8;
  width: 80rem;
  
}

.hero-button {
  outline: none;
  border: none;
  height: 8.25rem;
  width: 32.3rem;
  border-radius: 2rem;
  background: var(--primary-color);

  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 3rem;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 44px 0px #3fa0ef73;
  cursor: pointer;
  margin-top: 2rem;
}
.hero-button .hero-btn-right-arrow {
  margin-top: 0.2rem;
  margin-left: 2.5rem;
}

.btn-wrap {
  display: flex;
  column-gap: 50px;
}

.btn-wrap .demo-btn {
  background: #fff;
  color: #3fa0ef;
}

/***************************************************************/
@media (max-width: 1600px) {
  .hero-section-container {
    height: 100rem;
  }
}

@media (max-width: 1300px) {
  .hero-section-container {
    height: 85rem;
  }
}

@media (max-width: 650px) {
  .hero-heading {
    font-size: 11rem;
  }
  .hero-description {
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  .hero-section-container {
    height: 130rem;
    background-size: cover;
    background-position-x: 89%;
    gap: 5rem;
  }
  .hero-heading {
    width: 70%;
    text-align: left;
    font-size: 12.8rem;
  }
  .hero-description {
    width: 75%;
    font-size: 2.8rem;
    line-height: 5rem;
    text-align: left;
    margin-left: 4rem;
  }

  .hero-button {
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;
    width: 46.4rem;
    height: 12.2rem;
    font-size: 3.2rem;
    line-height: 3rem;
    border-radius: 4rem;
  }
  .btn-wrap {
    flex-direction: column;
    row-gap: 20px;
  }
}
