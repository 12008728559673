.infoHeader-header-section {
  display: flex;
  flex-direction: column;
  gap: 2.368rem;
}

.infoHeader-title {
  display: flex;
  align-items: center;
  gap: 1rem;

  font-family: "Cabin-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #838383;
}

.infoHeader-heading-dash {
  width: 4.28rem;
  height: 0.142rem;
  border: 0.1rem solid #979797;
}

.infoHeader-heading-and-desc {
  display: flex;
  justify-content: space-between;
  /* width: 90%; */
  column-gap: 4rem;
  padding-left: 5.28rem;
}
.infoHeader-heading {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5.2rem;
  letter-spacing: 0.018rem;
  color: var(--secondary-color);
  width: 40rem;
}

.infoHeader-heading-period-style {
  color: var(--primary-color);
}

.infoHeader-description {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.6rem;
  color: #838383;

  width: 55.3rem;
  justify-self: end;

  margin-top: 0.8rem;
}

@media (max-width: 991px) {
  .infoHeader-heading-and-desc {
    flex-direction: column;
    row-gap: 2rem;
    padding-left: 0;
  }
  .infoHeader-heading {
    width: 90%;
    color: var(--secondary-color);
  }
  .infoHeader-description {
    width: 90%;
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .infoHeader-title {
    font-size: 3.6rem;
    line-height: 2.6rem;
  }
  .infoHeader-heading {
    width: 100%;
    font-size: 5rem;
    line-height: 8rem;
  }
  .infoHeader-description {
    width: 100%;
    margin-top: 0;
    font-size: 16px;
    line-height: 5.2rem;
  }
}
