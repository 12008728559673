.App {
  width: 100vw;
  max-width: 1920px;
  /* height: 100vh; */
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
  background-color: #FFFFFF;;
}

.slick-slide * {
  outline: none !important;
}

.floatinForm-wrapper{
  position: fixed;
  right: 1%;
  bottom: 0.8%;
  z-index: 1000;
}