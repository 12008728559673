*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #3fa0ef;
  --secondary-color: #4c5151;
}

html {
  font-size: 10px;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/********************responsive font-sizes style**************/
@media (max-width: 1700px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 8.5px;
  }
}
@media (max-width: 1300px) {
  html {
    font-size: 8.5px;
  }
}
@media (max-width: 1100px) {
  html {
    font-size: 8px;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 7px;
  }
}
@media (max-width: 700px) {
  html {
    font-size: 6px;
  }
}
@media (max-width: 500px) {
  html {
    font-size: 6px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 5px;
  }
}
@media (max-width: 300px) {
  html {
    font-size: 3.5px;
  }
}
