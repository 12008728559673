.floatingform-container {
  width: 40rem;
  height: 58rem;
  border-radius: 1.6rem 1.6rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url(/src/assets/images/homePage/chatFormBG.png);
  background-repeat: no-repeat;
  background-position: center -282px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.floatingform-close-btn {
  position: absolute;
  right: 2.6%;
  top: 2%;
  outline: none;
  border: none;
  background: none;
  font-size: 2.3rem;
  cursor: pointer;
  color: white;
}

.floatingform-header {
  color: #ffffff;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.8rem;
}
.floatingform-header > img {
  width: 4.8rem;
  height: 4.8rem;
}

.floatingform-header-title {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 3rem;
  width: 120%;
  text-align: center;
}

.floatingform-header-desc {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  text-align: center;
  width: 70%;
}
/* ***************************** */
.floatingform-inputs-container {
  margin-top: 1rem;
  width: 90%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.7rem 1.7rem;
  border-radius: 0.8rem;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
}
.floatingform-inputs-container > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.floatingform-inputs-container > div > label {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #5a7184;
}

.floatingform-inputs-container > div > input {
  outline: none;
  border: none;
  width: 32.4rem;
  height: 2.5rem;
  background: #ffffff;
  border: 1px solid #c3cad9;
  border-radius: 0.8rem;
  padding-left: 1rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  color: #183b56;
}
.floatingform-inputs-container > div > input::placeholder {
  color: #959ead;
}
.floatingform-inputs-container > div > textarea {
  outline: none;
  border: none;
  background: none;
  background: #ffffff;
  border: 1px solid #c3cad9;
  border-radius: 0.8rem;
  padding-left: 1rem;
  padding-top: 0.8rem;
  min-width: 32.4rem;
  max-width: 32.4rem;
  height: 5rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  color: #183b56;
}
.floatingform-inputs-container > div > textarea::placeholder {
  color: #959ead;
}

.country-input-styling {
  outline: none;
  border: none;
  width: 32.4rem;
  background: #ffffff;
  border-color: #c3cad9;
  border-radius: 0.8rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  color: #183b56;
}

.floatingform-agreement-msg {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #5a7184;
}
.floatingform-submit-btn {
  text-align: center;
  outline: none;
  border: none;
  width: 32.4rem;
  height: 3.3rem;
  background: #368ef4;
  border-radius: 0.7rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  color: #ffffff;
  cursor: pointer;
}

/* ********************mobile responsive******************** */

@media (max-width: 500px) {
  .floatingform-container {
    width: 85vw;
    height: 72vh;
    background-position: center -45rem;
  }
  .floatingform-close-btn {
    font-size: 4.6rem;
  }
  .floatingform-header {
    margin-top: 1.5rem;
  }
  .floatingform-header > img {
    width: 6.5rem;
    height: 6.5rem;
  }
  .floatingform-header-title {
    font-size: 2.4rem;
    line-height: 5rem;
  }

  .floatingform-header-desc {
    font-size: 2.3rem;
    text-align: center;
    width: 80%;
  }
  .floatingform-inputs-container {
    margin-top: 1.7rem;
    gap: 2rem;
  }
  .floatingform-inputs-container > div {
    gap: 1.3rem;
  }
  .floatingform-inputs-container > div > label {
    font-size: 2.2rem;
    line-height: 2.5rem;
  }

  .floatingform-inputs-container > div > input {
    width: 96%;
    height: 4.3rem;
    font-size: 2rem;
  }
  .country-input-styling {
    width: 96%;
    height: 5rem;
    font-size: 2rem;
  }

  .floatingform-inputs-container > div > textarea {
    min-width: 96%;
    max-width: 96%;
    height: 12rem;
    font-size: 2rem;
  }
  .floatingform-agreement-msg {
    font-size: 1.9rem;
    line-height: 2.9rem;
  }
  .floatingform-submit-btn {
    width: 96%;
    height: 5.3rem;
    font-size: 2.5rem;
    line-height: 4rem;
  }
}

@media (max-width: 400px) {
  .floatingform-container {
    width: 85vw;
    height: 63vh;
    background-position: center -60rem;
  }
  .floatingform-header {
    margin-top: 3rem;
  }
}

@media (max-width: 375px) {
  .floatingform-container {
    width: 85vw;
    background-position: center -60rem;
  }
}
@media (max-width: 360px) {
  .floatingform-container {
    width: 85vw;
    background-position: center -60rem;
  }
}
