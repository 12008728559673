/* Cabin font family starts*/
@font-face {
  font-family: "Cabin-Regular";
  src: local("Cabin-Regular"),
    url("../assets/fonts/cabin/Cabin-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cabin-Medium";
  src: local("Cabin-Medium"),
    url("../assets/fonts/cabin/Cabin-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Cabin-SemiBold";
  src: local("Cabin-SemiBold"),
    url("../assets/fonts/cabin/Cabin-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Cabin-Bold";
  src: local("Cabin-Bold"),
    url("../assets/fonts/cabin/Cabin-Bold.ttf") format("truetype");
}

/* Cabin font family ends */

/* Circular-Std font family starts*/
@font-face {
  font-family: "CircularStd-Medium";
  src: local("CircularStd-Medium"),
    url("../assets/fonts/circular-std/CircularStd-Medium.otf")
      format("opentype");
}
/* Circular-Std font family ends */

/* mazzard font family starts*/
@font-face {
  font-family: "Mazzard-Regular";
  src: local("Mazzard-Regular"),
    url("../assets/fonts/mazzard/MazzardH-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Mazzard-Medium";
  src: local("Mazzard-Medium"),
    url("../assets/fonts/mazzard/MazzardH-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Mazzard-SemiBold";
  src: local("Mazzard-SemiBold"),
    url("../assets/fonts/mazzard/MazzardH-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Mazzard-Bold";
  src: local("Mazzard-Bold"),
    url("../assets/fonts/mazzard/MazzardH-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Mazzard-ExtraBold";
  src: local("Mazzard-ExtraBold"),
    url("../assets/fonts/mazzard/MazzardH-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: "Mazzard-Black";
  src: local("Mazzard-Black"),
    url("../assets/fonts/mazzard/MazzardH-Black.otf") format("opentype");
}
/* mazzard font family ends */

/* Montserrat font family starts*/
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("../assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("../assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("../assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"),
    url("../assets/fonts/montserrat/Montserrat-Black.ttf") format("truetype");
}

/* Montserrat font family ends */

/* Raleway font family starts*/
@font-face {
  font-family: "Raleway-Regular";
  src: local("Raleway-Regular"),
    url("../assets/fonts/raleway/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-Medium";
  src: local("Raleway-Medium"),
    url("../assets/fonts/raleway/Raleway-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: local("Raleway-SemiBold"),
    url("../assets/fonts/raleway/Raleway-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"),
    url("../assets/fonts/raleway/Raleway-Bold.ttf") format("truetype");
}

/* Raleway font family ends */
