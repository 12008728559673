.blog-container {
  width: 100%;
  max-width: 70vw;
  margin: 0 auto;
  height: fit-content;
  padding: 7.37rem 0 11rem;
  position: relative;
  top: 9rem;
}

.blog-container > .main-blog {
  padding: 5rem 0 10rem;
}

.blog-container > .main-blog {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
  justify-content: center;
  column-gap: 20px;
}

.main-blog > .main-post-image,
.main-blog > .main-blog-content {
  width: 50%;
}

.main-blog > .main-post-image > img {
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px #5f5f5f26;
}

.main-blog-title {
  font-size: 42px;
}

.blog-container .main-blog-content {
  row-gap: 20px;
  font-size: 16px;
}

.blog-container > .remaining-blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 320px));
  gap: 30px 10px;
  justify-content: center;
}

.blog-container .blog-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 20px;
}

.blog-card .blog-data-and-buttons {
  margin-top: 20px;
  row-gap: 10px;
  justify-content: space-between;
  height: 100%;
}

.blog-card .content-wrapper h2 {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.4rem;
  margin-bottom: 10px;
}

.blog-card .content-wrapper p {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.6rem;
  color: #838383;
}

.blog-card .blog-data-and-buttons .learn-more {
  margin-right: 8px;
}

.blog-card .other-singlepost-image > img {
  width: 100%;
  height: 210px;
  min-width: 250px;
  max-width: 320px;
  object-fit: cover;
  border-radius: 10px;
}

.remaining-blog-list-publish-time {
  font-family: "Cabin-Regular";
  font-size: 16px;
  color: #838383;
  margin-top: 10px;
}

.desktop-hidden {
  display: none;
}

@media (max-width: 1500px) {
  .blog-container {
    max-width: 80vw;
  }
}

@media (max-width: 1400px) {
  .main-blog-title{
    font-size: 32px;
  }
}

@media (max-width: 1200px) {
  .blog-container {
    max-width: 86%;
  }
  .blog-container > .main-blog {
    flex-direction: column;
    padding: 0;
  }
  .blog-container .main-blog > .main-post-image,
  .blog-container .main-blog > .main-blog-content {
    width: 100%;
  }
  .main-blog-title {
    font-size: 34px;
  }
}

@media (max-width: 812px) {
  .desktop-hidden {
    display: block;
    margin: 0 0 10px;
  }
  .tablet-hidden {
    display: none;
  }
  .blog-container .remaining-blog-list {
    display: flex;
    flex-direction: column;
  }
  .blog-container .blog-card {
    column-gap: 20px;
    flex-direction: row;
    padding: 20px 0;
  }
  .blog-card .other-singlepost-image > img {
    height: 200px;
    width: 250px;
  }
  .blog-card .content-wrapper h2 {
    font-size: 24px;
  }
  .blog-card .blog-data-and-buttons {
    margin-top: 0;
  }
  .blog-card .content-wrapper p {
    line-height: 3.6rem;
  }
}

@media (max-width: 575px) {
  .blog-container .main-blog > .main-post-image,
  .blog-container .main-blog > .main-blog-content {
    padding: 14px 0;
  }
  .blog-container .main-blog-content {
    row-gap: 10px;
  }
  .main-blog-title {
    font-size: 28px;
  }
  .blog-container .blog-card {
    flex-direction: column;
    padding: 0 0 20px;
  }
  .blog-container .remaining-blog-list {
    row-gap: 0;
  }
  .blog-card .other-singlepost-image > img {
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
  .blog-card .content-wrapper h2 {
    font-size: 18px;
    margin-top: 20px;
  }
  .main-blog-description {
    font-family: "Cabin-Regular";
    color: #838383;
    font-size: 18px;
  }
  .desktop-hidden {
    margin-top: 10px;
  }
}
