.footer-main {
  margin: 0 auto;
  position: relative;
  /* margin-top: 17rem; */
}

/*---------------------------------*/
.footer-main-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0px 10px 20px rgba(0, 33, 67, 0.1);
  background-image: url(../../../assets/images/homePage/footerSectionMask.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.footer-navigation-bar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: fit-content;
  padding-top: 4rem;
}
.footer-hyreworks-logo {
  width: 48rem;
  height: 4.6rem;
  margin-left: 12rem;
  cursor: pointer;
}
.footer-links {
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.5fr;
  place-content: center;
  grid-column-gap: 4rem;
  place-items: center;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: #050505;
  place-content: center;
  place-items: center;
  margin-right: 15rem;
}
.footer-links > * {
  cursor: pointer;
}
.footer-border {
  margin-top: 4rem;
  mix-blend-mode: normal;
  opacity: 0.2;
  border: 1px solid #c3cedd;
}
.footer-bottom-form-wrapper {
  display: flex;
  flex-direction: column;
}
.footer-madewith {
  font-size: 1.8rem;
}
.footer-rights-responsive {
  display: none;
}
.footer-text {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  padding: 3.5rem 0rem 9.5rem;
}
.footer-copyright {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0f0f0f;
}
.footer-privacy-policy {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #020202;
}
/************************** Media Queries *********************/
@media (max-width: 1600px) {
  .footer-navigation-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem;
  }
  .footer-hyreworks-logo {
    margin-left: 0;
  }
  .footer-links {
    margin-right: 0;
    grid-column-gap: 2.5rem;
    font-size: 16px;
    line-height: 23px;
  }
}
@media (max-width: 1150px) {
  .footer-links {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 2rem;
  }
}

@media (max-width: 955px) {
  .footer-text {
    flex-direction: column;
    padding: 3.5rem 3.5rem 9.5rem 3.5rem;
  }
  .footer-madewith {
    margin-bottom: 1.8rem;
  }
}

@media (max-width: 900px) {
  .footer-navigation-bar {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding-left: 0;
    padding-right: 0;
  }
  .footer-madewith {
    font-size: 13px;
  }
  .footer-privacy-policy {
    font-size: 14px;
  }
  .footer-links {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 2rem;
  }
  .footer-hyreworks-logo {
    margin: 0 auto;
  }
  .footer-text {
    width: 100%;
    flex-direction: column;
    padding: 3.5rem 3.5rem 9.5rem 3.5rem;
  }
}
@media (max-width: 670px) {
  .footer-links {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 2rem;
  }
  .footer-border {
    margin-top: 6rem;
    margin-bottom: 2rem;
  }
  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 4rem;
  }
}
@media (max-width: 550px) {
  .footer-links {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    place-content: center;
    place-items: center;
    grid-column-gap: 0;
  }

  .footer-copyright {
    font-size: 2.6rem;
    line-height: 5.2rem;
  }
  .footer-privacy-policy {
    font-size: 2.6rem;
    line-height: 3rem;
  }
}
@media (max-width: 500px) {
  .footer-main-content {
    background-image: none;
    background-color: #ebf5ff;
  }

  .footer-rights {
    display: none;
  }
  .footer-rights-responsive {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
}
@media (max-width: 430px) {
  .footer-navigation-bar {
    gap: 8rem;
  }
  .footer-links {
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
    place-content: center;
    place-items: center;
    grid-column-gap: 0;
  }
}

@media (max-width: 397px) {
  .footer-madewith {
    text-align: center;
  }
}
