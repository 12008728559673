.talkCard-main {
  display: flex;
  width: 85.26%;
  height: 25rem;
  margin: 0 auto;
  padding: 11rem 13.2rem 20rem;
  justify-content:center  ;
  align-items: center;
  background: linear-gradient(159.54deg, #3fa0ef 15.79%, #b6deff 94.98%);
}
.talkCard {
  display: flex;
  flex-direction: column;
}

.talCard-text{
  margin: auto;
}

.text-up {
  padding: 0rem 5rem 0 5rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 3.1rem;
  line-height: 5.2rem;
  letter-spacing: 0.018rem;
  color: #ffffff;
}
.text-down {
  padding: 2rem 5rem 0 5rem;
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 2.6rem;
  color: #ffffff;
}
.talkCard-btn {
  height: 7rem;
  width: 20rem;
  border: none;
  color: var(--secondary-color) !important;
  background: linear-gradient(180deg, #ffac6d 0%, #fe743b 100%);
  border-radius: 0.3rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transform: translate(0rem, 6rem);
  border-radius: 8px;
}
/****** Media Queries *****/
@media (max-width: 1120px) {

  .talkCard-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 36rem;
        /* margin: 0;
    padding: 0; */
  }
  .talkCard-btn {
    margin-top: 2.5rem;
    margin-left: 5rem;
    transform: none;
    padding: 1rem;
  }

}

@media (min-wdth: 575px) and (max-width: 600px) {
  
}

@media (max-width: 500px) {
  .talkCard-main {
    width: 100%;
    height: 85rem;
    margin: 0;
    padding: 0;
  }
  .text-up {
    font-size: 7.2rem;
    line-height: 10.4rem;
    padding-top: 5rem;
  }

  .text-down {
    font-size: 4rem;
    line-height: 5.2rem;
  }
  .talkCard-btn {
    width: 24.3rem;
    font-size: 2.5rem;
    line-height: 3.8rem;
    margin-top: 6rem;
    margin-bottom: 5rem;
    transform: none;
  }
}

@media (max-width: 500px) {
  .text-up {
    font-size: 6rem;
  }
}
