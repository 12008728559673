.navbar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  color: black;
  height: 9rem;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
  transition: all 200ms ease-out;
}

.navbar.active {
  background-color: #ffffff;
  transition: all 200ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 8rem;
  min-height: 70px; */
  max-width: 1820px;
}

.navbar-logo {
  color: black;
  justify-self: start;
  margin-left: 2rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 3rem;
  display: flex;
  align-items: center;
}
.navbar-logo > img {
  width: 33.2rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  /* grid-gap: 1rem; */
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2rem;
  height: 100%;

  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #213053;
}

.nav-links:hover {
  border-bottom: 4px solid #213053;
  transition: all 0.2s linear;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-getStarted-button {
  outline: none;
  border: none;
  width: 15.5rem;
  height: 5.4rem;
  border-radius: 1.5rem;
  background-color: var(--primary-color);

  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 16px 36px 0px #3fa0ef4d;
  cursor: pointer;
}
.nav-getStarted-button > a {
  text-decoration: none;
  color: #ffffff;
}

@media only screen and (min-width: 3000px) {
  .nav-menu {
    width: 40vw;
  }
}

@media screen and (max-width: 1150px) {
  .nav-menu {
    width: 54vw;
  }
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    padding-bottom: 30px;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: ;
  }

  .nav-menu.active {
    background: whitesmoke;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-links:hover {
    background-color: rgb(197, 197, 196);
    color: #213053;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 3%;
    font-size: 3rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 0.4rem;
    width: 70%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: black;
    padding: 1.4rem 2rem;
    border: 1px solid black;
    transition: all 0.3s ease-out;

    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 2.6rem;
    text-align: center;
    color: #213053;
  }

  .nav-links-mobile:hover {
    background: #ffffff;
    color: black;
    transition: 250ms;
  }
}

@media screen and (max-width: 500px) {
  .nav-links {
    font-size: 3.6rem;
  }
  .menu-icon {
    font-size: 4rem;
    right: 10%;
  }
  .navbar-logo {
    font-size: 4rem;
    padding-left: 7rem;
  }
  .nav-menu {
    padding-bottom: 80px;
  }
}
