.mb-2{
  margin-bottom: 2rem;
}

.aboutus-container {
  width: 100%;
  height: fit-content;
  background: rgba(34, 176, 252, 0.06);
  padding-top: 4.164rem;
  padding-left: 16.1rem;
  padding-bottom: 7rem;
  position: relative;
  scroll-margin: 80px;
}

.aboutus-container::after {
  content: "";
  position: absolute;
  width: 8%;
  height: 8%;
  background: url(../../../../assets//images/homePage/dottedNetMask.png);
  background-size: contain;
  background-repeat: no-repeat;
  right: 17%;
  top: 96%;
}


.aboutUs-heading {
  display: flex;
  flex-direction: column;
  margin-right: 22.5rem;
  gap: 2.7rem;
}

.aboutUs-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.aboutUs-title-dash {
  width: 4.28rem;
  height: 0.142rem;
  mix-blend-mode: normal;
  border: 0.1rem solid #979797;
}

.aboutUs-title-text {
  display: flex;
  font-family: "Cabin-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #838383;
}

.aboutUs-heading-and-desc {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.aboutUs-heading-text {
  font-family: "Raleway-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 5rem;
  letter-spacing: 0.018rem;
  color: var(--secondary-color);
  padding-left: 5.15rem;
}

.aboutUs-heading-desc {
  width: fit-content;
  font-family: "Cabin-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #838383;
  padding-left: 5.15rem;
}

.aboutus-carousel-outer-div {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.aboutus-slider-progressbar {
  margin-top: 6rem;
  display: none;
}

.aboutus-carousel-all-content{
  max-width: 714px !important;
  height: 400px !important;
  padding-right: 1rem;
}

.aboutus-img {
  width: 100% !important;
  height: 400px !important;
}


/********************************************************/
@media (max-width: 1770px) {
  .aboutus-slider-styling {
    margin: 0 -100px 0 0;
  }
}
@media (max-width: 1670px) {
  .aboutus-slider-styling {
    margin: 0 -200px 0 0;
  }
}
@media (max-width: 1570px) {
  .aboutus-slider-styling {
    margin: 0 -300px 0 0;
  }
}
@media (max-width: 1500px) {
  .aboutus-slider-styling {
    margin: 0 120px 0 0;
  }
}
@media (max-width: 1400px) {
  .aboutus-slider-styling {
    margin: 0 -10px 0 0;
  }
}
@media (max-width: 1300px) {
  .aboutus-slider-styling {
    margin: 0 -80px 0 0;
  }
}
@media (max-width: 1200px) {
  .aboutus-slider-styling {
    margin: 0 -200px 0 0;
  }
  .aboutus-carousel-outer-div {
    padding-left: 0px;
  }
}
@media (max-width: 1100px) {
  .aboutus-slider-styling {
    margin: 0 -300px 0 0;
  }
}


@media (max-width: 1700px) {
  .aboutus-carousel-all-content {
    width: 650px;
    height: 400px;
  }
}
.aboutus-img{
  object-fit: contain;
}
@media (max-width: 1000px) {

  .aboutus-container {
    padding-left: 6.3rem;
    padding-bottom: 6rem;
    padding-top: 7rem;
  }

  .aboutus-container::after {
    display: none;
  }

  .aboutus-carousel-outer-div {
    margin-top: 8rem;
  }
  .aboutus-carousel-all-content {
    width: 450px;
  height: 400px;
    /* height: fit-content; */
    }
}

@media (max-width: 500px) {

  .aboutus-container::after {
    display: none;
  }
  .aboutUs-heading-text {
    font-size: 3.2rem;
    line-height: 5.2rem;
  }

  .aboutUs-heading-desc {
    font-size: 2.7rem;
    line-height: 4rem;
  }

  .aboutus-carousel-outer-div {
    margin-top: 7rem;
    margin-left: -4rem;
  }
  .aboutus-carousel-all-content {
    width: 300px !important;
    height: 200px !important;
    padding-right: 0 !important;
  }

  .aboutus-img{
    /* width: 250px; */
    height: 300px !important;
  }
  .aboutus-slider-styling {
    margin: 0 -200px 0 0;
  }

  .aboutus-slider-styling .slick-slide {
    padding-right: 5rem;
  }

  .aboutus-slider-progressbar {
    display: block;
    width: 80%;
    height: 1.1rem;
    border-radius: 1rem;
    background-color: rgba(34, 176, 252, 0.2);
    position: relative;
  }

  .aboutus-slider-progressDot {
    position: absolute;
    left: 0;
    width: 12%;
    height: 100%;
    background-color: #1d293f;
    border-radius: 1rem;
    transition: left 900ms ease-in-out;
  }
}
