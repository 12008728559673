.blog-page {
  width: 100%;
  max-width: 70vw;
  margin: 0 auto;
  height: fit-content;
  padding-top: 7.37rem;
  padding-bottom: 11rem;
  position: relative;
  top: 9rem;
}

.blog-page .feature-blog-title {
  font-family: NeueMontreal, sans-serif;
  font-size: 52px;
  font-weight: 700;
  margin: 0 0 24px;
}

.blog-page > h1 {
  font-size: 32px;
  margin: 40px 0 0;
}

.blog-page > .cover-image > img {
  margin: 0;
  padding: 10px 0;
  width: 100%;
}

.blog-page > .cover-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-page > .content-images {
  /* display: flex; */
  /* float: left; */
  /* padding-right: 20px; */
  justify-content: start;
}

.content-images > img {
  margin: 16px 0;
}

.blog-page > p,
.blog-page > .info > p {
}

.blog-page > .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-page > .info > .social-contacts {
  display: flex;
  column-gap: 20px;
}

/* Typography */
.blog-h1{
  font-size: 32px;
  color: #222;
}
.blog-h2{
  font-size: 28px;
}
.blog-para{
  margin: 10px 0;
  color: #838383;
  font-size: 18px;
  line-height: 30px;
}
.blog-ol,.blog-ul{
  /* list-style-type: none; */
  padding: 0;
  margin: 0;
  padding-left: 16px;
  margin-bottom: 14px;
}
.blog-li{
  color: #838383;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
}

@media (max-width: 991px) {
  .blog-page {
    max-width: 86%;
  }
  .blog-page .feature-blog-title {
    font-size: 42px;
  }
}

@media (max-width: 575px) {
  .content-images > img {
    height: 100%;
    width: 100%;
  }
  .blog-page .feature-blog-title {
    font-size: 32px;
  }
}
