.pricing-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* outline: 1px solid blue; */
    margin-top: 8rem;
    height: 63rem;
    scroll-margin: 125px;
  }
  
  .pricing-heading {
    display: flex;
    flex-direction: column;
    margin-left: 16rem;
    margin-right: 22.5rem;
    gap: 2.7rem;
  }
  
  .pricing-title {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .pricing-title-dash {
    width: 4.28rem;
    height: 0.142rem;
    mix-blend-mode: normal;
    border: 0.1rem solid #979797;
  }
  
  .pricing-title-text {
    display: flex;
    font-family: "Cabin-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #838383;
  }
  
  .pricing-heading-and-desc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20rem;
  }
  
  .pricing-heading-text {
    width: 42.857rem;
    font-family: "Raleway-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 5.2rem;
    letter-spacing: 0.018rem;
    color: #000000;
    padding-left: 5.15rem;
  }
  
  .pricing-heading-desc {
    width: fit-content;
    font-family: "Cabin-Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #838383;
  }
  
  /***************** CARDS *****************/

  .pricing-cards {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    margin: 0px auto;
    margin-top: 7rem;
    margin-left: 16rem;
    margin-right: 16rem;
  
    min-height: fit-content;
    max-height: 100%;
  }
  
  .pricing-individual-card {
    width: 42.8rem;
    height: 38rem;
    padding-top: 5rem;
    padding-left: 3.5rem;
    background: #fcffff;
    box-shadow: 0px 5px 20px rgba(95, 95, 95, 0.1);
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    position: relative;
    gap: 0.8rem;
  }
  
  .card-title {
    /* width: 21.2rem; */
    font-family: "Raleway-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.4rem;
    color: var(--secondary-color);
  }
  
  .card-desc {
    width: 36.5rem;
    font-family: "Cabin-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: #838383;
    padding-right: 2.7rem;
    margin-top: 2.5rem;
  }
  
  /* .learnMore-and-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 2rem;
    position: absolute;
    bottom: 3rem;
  }
  
  .card-learn-more {
    font-family: "Raleway-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #fe743b;
    cursor: pointer;
  }
  
  .card-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8rem;
    gap: 0;
    cursor: pointer;
  }
  
  .card-arrow-head {
    color: #fe743b;
    font-size: 2.2rem;
  }
  
  .card-arrow-tail {
    width: 2.5rem;
    height: 0.2rem;
    background-color: #fe743b;
    transform: translateX(1.3rem);
    transition: width 0.3s linear;
  } */
  
  .pricing-individual-card::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #5cbafa 0%, #2084c6 100%);
    transition: opacity 0.8s linear;
    opacity: 0;
  }
  
  .pricing-individual-card:hover::before {
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 5px 20px rgba(95, 95, 95, 0.1);
  }
  
  .pricing-individual-card:hover .hoverText {
    color: #ffffff;
    z-index: 1;
  }
  .pricing-individual-card:hover .card-arrow-tail {
    width: 4rem;
    background: #ffffff;
    transition: width 0.3s linear;
  }
  
  /***************** BackGround Div *****************/
   
  @media (max-width: 1280px) {
    .pricing-cards {
      margin-left: 7rem;
    }
  }
  
  @media (max-width: 1230px) {
    .pricing-main {
        height: 100rem;
      }
    .pricing-heading-and-desc {
      display: flex;
      flex-direction: column;
    }
    .pricing-heading-text {
      padding-left: 0;
    }
    .pricing-individual-card {
      height: auto;
      padding-top: 0.6rem;
      padding-left: 0.7rem;
    }
    .pricing-cards {
      margin-top: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-left: 14rem;
    }
    .pricing-individual-card {
      padding-top: 7.7rem;
      padding-left: 2rem;
    }
    .learnMore-and-arrow {
      margin-top: 0;
    }
  }
  
  @media (max-width: 800px) {
    .pricing-title-text {
      width: 90.857rem !important;
      height: fit-content;
    }
    .pricing-cards {
      margin-top: 0;
      top: 0;
    }
    .pricing-individual-card {
      padding-top: 7.7rem;
      padding-left: 2rem;
    }
    .learnMore-and-arrow {
      margin-top: 0;
    }
  }
  
  @media (max-width: 600px) {
    .pricing-heading {
      margin-left: 4.3rem;
    }
    .pricing-main {
      margin: 0 1rem;
    }
  
    .pricing-individual-card {
      padding-top: 2rem;
      padding-left: 2rem;
    }
  
    .pricing-cards {
      padding: 1rem 0;
    }
  }
  
  @media (max-width: 500px) {
    .pricing-main {
      margin: 15rem 0;
      height: 258rem;
    }
    .pricing-heading {
      margin-left: 6.5rem;
    }
    .pricing-heading-desc {
      width: 83vw;
    }
    .pricing-title-dash {
      width: 9.2rem;
    }
    .pricing-title-text {
      font-size: 3.6rem;
      line-height: 5.2rem;
    }
    .pricing-heading-text {
      width: 120%;
      font-size: 7.2rem;
      line-height: 10.4rem;
    }
    .pricing-heading-desc {
      font-size: 2.8rem;
      line-height: 5.2rem;
      margin: 4rem 0;
    }
    .pricing-cards {
      padding: 8rem 0;
      margin: 0 auto;
    }
  
    .pricing-individual-card {
      width: 81vw;
      height: 54rem;
      padding-top: 3rem;
      padding-left: 3rem;
    }
    .card-title {
      width: 100%;
      font-size: 4.8rem;
      line-height: 6.8rem;
    }
    .card-desc {
      width: 100%;
      font-size: 2.8rem;
      line-height: 5.2rem;
    }
  
    .card-learn-more {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
  
    .card-arrow {
      margin-right: 20rem;
    }
    .card-arrow-head {
      font-size: 5.2rem;
    }
    
    .card-arrow-tail {
      width: 8.5rem;
      height: 0.4rem;
      transform: translateX(2.8rem);
    }
  
    .pricing-individual-card:hover .card-arrow-tail {
      width: 8.5rem;
    }
    
  }
  