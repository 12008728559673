.contact-wrapper {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  padding-bottom: 10rem;
}

.contact-text-heading-desc-container {
  color: #1d293f;
}

.contact-text-and-form-container {
  width: 100%;
  display: flex;
  column-gap: 17rem;
  justify-content: space-between;
}

.contact-text-heading-desc-container h2 {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 4.4rem;
  margin-bottom: 2.9rem;
}
.contact-text-heading-desc-container p {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  /* width: 47.2rem; */
  margin-bottom: 7.46rem;
}

.contact-text-allinfo-container > p {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 3.77rem;
}
.contact-text-info-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.9rem;
}

.icon-wrapper {
  display: flex;
  position: relative;
}

.contact-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  padding: 1rem;
  border-radius: 50%;
}

.single-contact-info {
  display: flex;
  align-items: start;
  gap: 1.88rem;
}
.single-contact-info-list-style {
  width: 2.832rem;
  height: 2.832rem;
  /* background: var(--primary-color); */
  border-radius: 50%;
}
.single-contact-info-detail {
  width: 30rem;
}
/* ***************************************************** */
.contactformCSS {
  display: flex;
  flex-direction: column;
  background: transparent;
}
.input-container {
  display: flex;
  gap: 15px;
}

.firstName-wrap,
.lastName-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* .input-container > div > input {
  width:27.300rem;
  margin-right: 1rem;
  margin-top: 1rem;
} */

.contactformCSS label {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.2rem;
  color: #333333;
  margin-bottom: 1rem;
}

.contactformCSS input {
  outline: none;
  border: none;
  background: none;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5rem;
  letter-spacing: 0.3px;
  color: #3f1d1d;
  /* width: 55.705rem; */
  height: 6rem;
  background: #feffff;
  border: 1px solid #eceef0;
  border-radius: 0.8rem;
  padding-left: 1.3rem;
  margin-bottom: 2rem;
}
.contactformCSS input::placeholder {
  color: #999999;
}

.contactformCSS textarea {
  border: none;
  background: none;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.3px;
  color: #3f1d1d;
  min-width: 55.705rem;
  max-width: 55.705rem;
  height: 20rem !important;
  border: 1px solid #eceef0;
  background: #feffff;
  border-radius: 1.5rem;

  padding-top: 1rem;
  padding-left: 1.3rem;
  margin-bottom: 1.7rem;
}
.contactformCSS textarea::placeholder {
  color: #999999;
}
.contactformCSS textarea:focus {
  outline: none;
}

.contactform-errorField {
  color: rgba(255, 0, 0, 0.897);
  font-style: "Inter-Regular";
  font-size: 1.3rem;
  line-height: 1rem;
  margin-bottom: 2rem;
  /* margin-top: -2rem; */
}

.disabled-submit-button{
  outline: none;
  border: none;
  width: 16rem;
  height: 5.1rem;
  text-align: center;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  border-radius: 0.8rem;
  cursor: not-allowed;
  color:  white;
  background: #ccc;
  align-self: flex-end;
}

.contactform-submitBtn {
  outline: none;
  border: none;
  width: 16rem;
  height: 5.1rem;
  text-align: center;
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--primary-color);
  color: #ffffff;
  align-self: flex-end;
}

.contact-icons {
  margin-top: 6rem;
}

.socialmedia-icon {
  margin-right: 2rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

/* ****************************************** */
@media (max-width: 1400px) {
  .contact-wrapper {
    width: 69%;
  }
}
@media (max-width: 1280px) {
  .contact-wrapper {
    width: 80%;
  }
}

@media (max-width: 1028px) {
  .contact-wrapper {
    width: 82%;
  }
}
@media (max-width: 1020px) {
  .contact-wrapper {
    width: 86%;
  }
  .contactformCSS textarea {
    min-width: 39rem;
    max-width: 44rem;
  }
  .contactformCSS input {
    width: 42rem;
  }

  .input-container > div > input {
    width: 18.9rem;
  }
}

@media (max-width: 718px) {
  .contact-text-and-form-container {
    column-gap: 13rem;
  }
}

@media (max-width: 635px) {
  .contact-text-and-form-container {
    width: 100%;
    flex-direction: column;
    row-gap: 10rem;
  }
  .contactformCSS label {
    font-size: 1.8rem;
  }
  .contactformCSS input {
    width: 100%;
    font-size: 1.8rem;
  }
  .contactformCSS textarea {
    min-width: 55.705rem;
    max-width: 100%;
  }
  .contactform-submitBtn {
    align-self: auto;
  }
  .input-container {
    flex-direction: column;
    gap: 0;
  }
  .input-container > div > input {
    width: 100%;
  }
  .socialmedia-icon {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 500px) {
  .contact-text-and-form-container {
    width: 100%;
    flex-direction: column;
    row-gap: 10rem;
  }
  .contact-text-heading-desc-container p {
    font-size: 2.5rem;
    line-height: 3.8rem;
  }
  .contact-text-allinfo-container > p {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }
  .contact-text-info-list {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .single-contact-info-detail {
    width: 55rem;
  }

  .contactformCSS label {
    font-size: 2.5rem;
    line-height: 2.9rem;
  }

  .contactformCSS input,
  .contactformCSS textarea {
    font-size: 2.4rem;
    line-height: 2rem;
    min-width: 55.705rem;
    max-width: 100%;
  }
  .contactformCSS input {
    height: 6rem;
    margin-bottom: 1.7rem;
  }
  .contactform-submitBtn {
    margin-top: 1rem;
    font-size: 2.4rem;
    align-self: flex-end;
    width: 22rem;
    height: 6rem;
  }
  .contactform-errorField {
    font-size: 2.2rem;
    line-height: 3rem;
    margin-bottom: 2.7rem;
    margin-top: -0.5rem;
  }
}
